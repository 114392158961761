// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-disclaimer-index-tsx": () => import("./../../../src/pages/disclaimer/index.tsx" /* webpackChunkName: "component---src-pages-disclaimer-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-index-tsx": () => import("./../../../src/pages/investors/index.tsx" /* webpackChunkName: "component---src-pages-investors-index-tsx" */),
  "component---src-pages-investors-oauth-sign-in-tsx": () => import("./../../../src/pages/investors/oauth-sign-in.tsx" /* webpackChunkName: "component---src-pages-investors-oauth-sign-in-tsx" */),
  "component---src-pages-livestream-tsx": () => import("./../../../src/pages/livestream.tsx" /* webpackChunkName: "component---src-pages-livestream-tsx" */),
  "component---src-pages-policy-index-tsx": () => import("./../../../src/pages/policy/index.tsx" /* webpackChunkName: "component---src-pages-policy-index-tsx" */),
  "component---src-templates-supplier-page-tsx": () => import("./../../../src/templates/supplier-page.tsx" /* webpackChunkName: "component---src-templates-supplier-page-tsx" */)
}

